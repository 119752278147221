/* Mentions.css */

.privacy {
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.privacy-container {
  max-width: 600px;
}

.privacy-title {
  margin-top: 9rem;
}

.privacy-list {
  padding: 1rem 0;
}

.privacy-text {
  line-height: 1.5;
}

.back-to-site-btn {
  display: block;
  width: 200px; /* Ajustez la largeur selon vos besoins */
  margin: 20px auto;
  padding: 10px 20px;
  text-align: center;
  color: white;
  background-color: black;
  border: 2px solid white;
  border-radius: 5px;
  text-decoration: none;
}

.back-to-site-btn:hover {
  background-color: white;
  color: black;
}

@media screen and (max-width: 760px) {
  .privacy {
    height: 100%;
  }

  .privacy-container {
    max-width: 300px;
  }

  .privacy-title {
    font-size: 1.5rem;
  }

  .privacy-subtitles {
    font-size: 0.9rem;
  }
}
