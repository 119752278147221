ul {
  list-style: none;
}
img {
  max-width: 100%;
}

.title {
  padding-top: 4rem;
  font-size: 2rem;
  text-align: center;
  color: black;
}

.btn-light {
  background: transparent;  
  color: #fff;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  transform: 0.3s;
}

/* Responsive */
@media screen and (max-width: 640px) {
  .title {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1040px) {
  .title {
    font-size: 1.5rem;
  }
}
