.logo-desc {
  width: 250px;
  height: 250px;
}


.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px 0;
  width: 100%;
}

.description-title {
  font-size: 1.5rem;
  margin-top: 2rem;
  text-align: center;
}

.description-text {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 700px;
  margin-top: 2rem;
  color: black;
  font-weight: 300;
}

/* RESPONSIVE */

@media screen and (max-width: 1040px) {
  .description-title {
    font-size: 1.8rem;
    margin-top: 2rem;
  }

  .description-text {
    font-size: 1rem;
    max-width: 500px;
  }
}

@media screen and (max-width: 640px) {
  .description {
    height: 100%;
  }

  .description-title {
    font-size: 1rem;
  }

  .logo-desc {
    margin-top: 2rem;
  }

  .description-text {
    max-width: 300px;
  }
}
