.banner {
  height: 120vh;
  padding-top: 2rem;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner img {
  margin: 0 auto;
  padding-top: 2rem;
  height: 60vh;
  width: 80%;
  object-fit: cover;
}

.banner-title {
  text-align: center;
  font-size: 4rem;
}

.banner p {
  margin-top: 2rem;
  max-width: 80%;
  text-align: justify;
  line-height: 1.7;
  padding-bottom: 2rem;
  opacity: 0.25;

}

@media screen and (max-width: 768px) {
  .banner-title {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .banner {
    height: 100%;
  }
}

