.biographie-border {
  display: flex;
  border: 1px solid #fe955a;
  background-color: #fe955a;
  width: 150px;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 5rem;
} 

.biographie {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 3rem;
}

.biographie-list {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.biographie-list p {
  text-align: justify;
  max-width: 600px;
  font-size: 1rem;
  line-height: 1.9;
}

.biographie h4 {
  text-align: justify;
  max-width: 600px;
  font-size: 1.2rem;
  font-weight: 500;
  font-style: oblique;
  margin-bottom: 1rem;
  line-height: 1.9;
}

.biographie-right {
  max-width: 50%;
}

.biographie-pic {
  width: 600px;
  height: auto;
}

.biographie-right img {
  box-shadow: 6px 6px 10px 5px black;
}


@media screen and (min-width: 768px) and (max-width: 1280px) {
  .biographie {
    display: flex;
    flex-direction: column-reverse;
  }

  .biographie-pic {
    margin-bottom: 5rem;
    height: 50%;
  }
  
}


@media screen and (max-width: 768px) {

  .biographie {
    display: flex;
    flex-direction: column-reverse;
  }

  .biographie-border {
    max-width: 30%;
  }

  .biographie-list {
    min-width: 100%;
    margin: 0 auto;
    text-align: justify;
  }

  .biographie-right {
    display: flex;
    min-width: 80%;
  }

  .biographie-pic {
    margin-bottom: 5rem;
    height: 350px;
    width: auto;
    object-fit: cover;
  }
}
