.news-link a {
  background-color: rgb(61, 136, 211);
  display: inline-block;
  padding: 12px 24px;
  margin-top: 1.5rem;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  font-style: oblique;
  text-align: center;
  text-decoration: none;
  color: #fff;
  box-shadow: 0 5px 0 black;
  text-shadow: 1px 2px black;
  transition: all 0.3s ease;
}


.news-link a:hover {
    background-position: 0 -50px;
    box-shadow: 0 2px 0 blue;
    transform: translateY(3px);
  }