.contact {
  background-color: black;
  color: white;
  height: 100%;
}

.contact .title {
  color: white;
}

.contact-border {
  border: 1px solid #FE955A;
  background-color: #FE955A;
  width: 350px;
  margin: 0 auto;
  margin-top: 1rem;
}

label {
  color: #fff;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.5rem;
}

form {
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem;
  margin: auto;
  max-width: 700px;
  height: 100%;
}

form input, form textarea {
  margin-bottom: 1rem;
  padding: 10px 18px;
  font-size: 1.2rem;
  background: none;
  border: none;
  color: #f4f4f4;
}

form input {
  border-bottom: 2px solid white;
  margin: 2rem 0;
}

.contact-btn {
  font-size: 1.5rem;
  font-weight: bold;
  width: 200px;
  margin: 0 auto;
  height: 50px;
  background: none;
  color: white;
  border: 1px solid white;
  cursor: pointer;
}



/* RESPONSIVE */

@media screen and (max-width: 1040px) {
  .contact {
    max-width: 100%;
  }

  .contact-border {
    max-width: 30%;
  }

  label {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 640px) {
  
  .form {
    max-width: 80%;
    height: 70%;
    margin: 0 auto;
  }

  .contact-border {
    max-width: 30%;
  }

  label {
    font-size: 0.7rem;
  }

  label placeholder {
    font-size: 0.6rem;
  }
}