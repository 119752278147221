.news .border {
  display: flex;
  border: 1px solid #fe955a;
  background-color: #fe955a;
  width: 150px;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.news {
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.news-container {
  display: flex;
  padding: 30px 0;
  justify-content: center;
  gap: 10px 30px;
  font-family: "Open Sans", sans-serif;
  align-items: center;
}

.news-content {
  display: flex;
  flex-direction: column;
  line-height: 1.8;
  text-align: justify;
}

#news-first {
  margin-left: 300px;
}

.news .title {
  color: white;
}

.news-title {
  font-family: "Open Sans", sans-serif;
}

.news-info {
  font-family: "Open Sans", sans-serif;
  color: rgb(61, 136, 211);
  font-size: 1.4rem;
}

.news-single, .news-single-info {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}


.news-text-span {
  font-weight: bold;
  font-style: oblique;
  color: white;
}

.news-img {
  max-width: 400px;
  height: auto;
}

.news-link a {
  background-color: rgb(61, 136, 211);
  display: inline-block;
  padding: 12px 24px;
  margin-top: 1.5rem;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  font-style: oblique;
  text-align: center;
  text-decoration: none;
  color: #fff;
  box-shadow: 0 5px 0 black;
  text-shadow: 1px 2px black;
  transition: all 0.3s ease;
}

.news-link a:hover {
  background-position: 0 -50px;
  box-shadow: 0 2px 0 blue;
  transform: translateY(3px);
}

.news-second-link {
  margin-left: 20px;
}

/* RESPONSIVE */

@media only screen and (max-width: 768px) {

  .news {
    height: 100%;
  }

  .news-img {
    max-width: 90%;
    margin: 0 auto;
  }

  .news-container {
    display: flex;
    flex-direction: column;
  }

  .news-title {
    font-size: 1.5rem;
    text-align: center;
  }

  .news-info, .news-single, .news-single-info {
    font-size: 1rem;
    text-align: center;;
  }

  .news-link{
    text-align: center;
  }

  .news-text {
    max-width: 90%;
    margin: 0 auto;
    padding-top: 1.5rem;
  }

  #news-first {
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {

  .news {
    height: 100%;
    padding: 1rem 0;
  }

  .news-container {
    margin: 0 5rem;
  }

  .news-text {
    max-width: 100%;
    font-size: 0.9rem;

  }

  .news-link a {
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }

  .news-img {
    max-width: 350px;
    max-height: 350px;
    object-fit: cover;
  }

  .news-container {
    font-size: 0.8rem;
  }

  #news-first {
    margin-left: 0;
  }

  .news-info, .news-single, .news-single-info {
    font-size: 1rem;
  }

  .news-second-link {
    margin-left: 0px;
  }
  
}



