/* Mentions.css */

.mentions {
  background-color: black;
  color: white;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.mentions-container {
  max-width: 600px;
}

.mentions-title {
  margin-top: 9rem;
}

.mentions-list {
  padding: 1rem 0;
}

.mentions-text {
  line-height: 1.5;
}

.back-to-site-btn {
  display: block;
  width: 200px; /* Ajustez la largeur selon vos besoins */
  margin: 20px auto;
  padding: 10px 20px;
  text-align: center;
  color: white;
  background-color: black;
  border: 2px solid white;
  border-radius: 5px;
  text-decoration: none;
}

.back-to-site-btn:hover {
  background-color: white;
  color: black;
}


@media screen and (max-width: 760px) {
  .mentions {
    height: 100%;
  }

  .mentions-container {
    max-width: 300px;
  }

  .mentions-title {
    font-size: 1.5rem;
  }

  .mentions-subtitles {
    font-size: 0.9rem;
  }
}

