.newsletter {
  background-color: black;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.newsletter h2 {
  color: white;
  text-align: center; /* Pour centrer le titre */
}

.newsletter-btn {
  display: inline-block;
  padding: 12px 14px;
  max-width: 40%;
  margin: 0 auto;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 1rem;
  font-weight: 600;
  font-style: oblique;
  text-align: center;
  text-decoration: none;
  color: black;
  background-color: white;
  transition: all 0.3s ease;
  cursor: pointer;
}

.newsletter-text {
    color: white;
    max-width: 100%;
    margin: 0 auto;
}

