.artistes {
  background-color: black;
}

.artistes .title {
  color: white;
}

.artistes-img {
  width: 300px;
  height: 400px;
  object-fit: cover;
}

.artistes-title {
  color: white;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.artistes-subtitle {
  padding: 1.5rem 0;
  margin-top: 1rem;
  color: white;
}

.artistes-border {
  border: 1px solid #fe955a;
  background-color: #fe955a;
  width: 150px;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 7rem;
}

.artistes-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  grid-gap: 40px;
  padding-bottom: 5rem;
  text-align: center;
}

.artistes-text {
  margin: 0 auto;
  max-width: 60%;
  text-align: justify;
  line-height: 1.7;
}

.artistes-item {
  color: white;
}

.artistes-subtitle:nth-child(3) {
  margin-top: 4rem;
}

.artistes-link {
  margin-top: 2rem;
}

.artistes-spotify {
  display: inline-block;
  padding: 12px 24px;
  margin-top: 1.5rem;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  font-style: oblique;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-image: linear-gradient(to bottom, #ee86ab, #ee86ab 80%);
  box-shadow: 0 5px 0 black;
  text-shadow: 1px 2px black;
  transition: all 0.3s ease;
}

.artistes-spotify:hover {
  background-position: 0 -50px;
  box-shadow: 0 2px 0 #d17837;
  transform: translateY(3px);
}

.read-more {
  background-color: black;
  color: white;
  border: none;
  font-size: 0.9rem;
  padding: 0.8rem;
  border-radius: 10px;
  margin-top: 1.5rem;
  cursor: pointer;
}

/* RESPONSIVE */


@media screen and (max-width: 640px) {

  .artistes-container {
    display: flex;
    flex-direction: column;
  }

  .artistes-title {
    font-size: 1rem;
  }

  .artistes-border {
    max-width: 30%;
    margin-bottom: 1.5rem;
  }

  .artistes-text {
    max-width: 80%;
  }

  .artistes-img {
    width: 300px;
    height: 400px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .artistes-text {
    max-width: 80%;
  }
}

