.production {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: black;
  color: white;
}

.production-container {
  display: flex;
  flex-direction: column;
}

.border {
  border: 1px solid #fe955a;
  background-color: #fe955a;
  width: 350px;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.production-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem;
  margin: 3rem 0;
  line-height: 1.8;
}

.production-list:nth-child(2) {
  flex-direction: row-reverse;
}

.production-title {
  font-size: 1.9rem;
  text-align: center;
  font-weight: 600;
  font-style: oblique;
  text-shadow: 1px 2px black;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #8190d3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
}

.production-meet {
  text-align: center;
  padding-top: 2rem;
  font-size: 2rem;
  font-style: oblique;
  font-weight: 300;
}

.production-subtitle {
  width: 700px;
  font-size: 1.3rem;
  font-style: oblique;
  font-weight: 400;
  margin-bottom: 1rem;
}

.production-text {
  text-align: justify;
  line-height: 2;
  width: 700px;
}

/* RESPONSIVE */

@media only screen and (max-width: 768px) {
  .production-container {
    padding: 0;
  }

  .border {
    width: 50%;
  }

  .production-list {
    flex-direction: column;
    padding: 1rem;
    margin: 1rem 0;
  }

  .production-list:nth-child(2) {
    flex-direction: column;
  }

  .production-title {
    background: none;
    width: 80%;
    height: 80%;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }

  .production-meet {
    font-size: 1.2rem;
  }


  .production-subtitle {
    width: 90%;
    font-size: 1.2rem;
  }

  .production-text {
    width: 90%;
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {

  .production-item {
    margin: 0 3rem;
  }

  .production-title {
    width: 280px;
    height: 280px;
    font-size: 1.7rem;
  }

  .production-meet {
    font-size: 1.3rem;
  }

  .production-subtitle {
    width: 100%;
    font-size: 1rem;
  }

  .production-text {
    width: 100%;
    font-size: 0.9rem;
  }
}
