.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
  background-color: black;
  color: white;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  gap: 0 60px;
}

.footer-link {
  text-decoration: none;
  color: white;
} 

.footer-link p {
  text-decoration: none;
  list-style: none;
}

@media screen and (max-width: 768px) {
    .footer-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.9rem;
      gap: 20px 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    gap: 20px 0;
  }
}